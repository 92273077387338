* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  min-height: 100vh;
  background-color: #100d26;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 150px;
}

@media only screen and (max-width: 520px) {
  .logo {
    left: 50%;
    transform: translateX(-50%);
  }
}
